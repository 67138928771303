import {
  MetricSummary, MetricFormData, PatchMetricRequest, CreateMetricRequest,
} from '@/store/metric/types';
import { v4 as uuid } from 'uuid';

export function metricSummaryToMetricForm(metric: MetricSummary): MetricFormData {
  const includeOptionsValidators = metric.validators
    .filter((validator) => validator.type === 'INCLUDE' && validator.options);

  const isDropdownField = includeOptionsValidators.length > 0;

  const dropdownFieldOptions = includeOptionsValidators
    .map((validator) => validator.options ?? [])
    .flat(1);

  const regexRules = metric.validators
    .filter((validator) => validator.regex)
    .map(({ regex, description, type }) => ({
      regex: regex as string, description: description ?? '', type, id: uuid(),
    }));

  const excludeValueLists = metric.validators
    .filter((validator) => validator.type === 'EXCLUDE' && validator.options)
    .map(({ description, options }) => ({ description: description ?? '', values: options ?? [], id: uuid() }));

  let associationType = 'NONE';
  if (metric.associatedEntityType) { associationType = 'ENTITY'; }
  if (metric.associatedRelationshipEntityTypes) { associationType = 'ENTITY_RELATIONSHIP'; }

  return {
    fin: metric.fin,
    name: metric.name,
    description: metric.description ?? '',
    isDropdownField,
    dropdownFieldOptions,
    regexRules,
    excludeValueLists,
    associationType,
    associatedET: metric.associatedEntityType ?? null,
    associatedRET: metric.associatedRelationshipEntityTypes ?? [null, null],
    isStatic: metric.isStatic,
    dataType: metric.type,
  };
}

export function metricFormToPatchMetricRequest(
  fin: string, form: MetricFormData,
): PatchMetricRequest {
  const regexValidators = form.regexRules.map(
    ({ description, type, regex }) => ({ description, type, regex }),
  );
  const excludedValuesValidators = form.excludeValueLists.map(({ description, values }) => ({ type: 'EXCLUDE', options: values, description }));
  const includeValuesValidator = form.isDropdownField ? [{ options: form.dropdownFieldOptions, type: 'INCLUDE' }] : [];
  const validators = [
    ...regexValidators,
    ...includeValuesValidator,
    ...excludedValuesValidators,
  ];
  return {
    fin,
    update: {
      name: form.name,
      description: form.description,
      validators,
      associatedEntityType: form.associationType === 'ENTITY' ? form.associatedET?.fin : null,
      associatedEntityRelationshipTypes: form.associationType === 'ENTITY_RELATIONSHIP' ? {
        parent: form.associatedRET[0]?.fin as string,
        child: form.associatedRET[1]?.fin as string,
      } : null,
      isStatic: form.isStatic,
    },
  };
}

export function metricFormToCreateMetricRequest(
  form: MetricFormData,
): CreateMetricRequest {
  const regexValidators = form.regexRules.map(
    ({ description, type, regex }) => ({ description, type, regex }),
  );

  const excludedValuesValidators = form.excludeValueLists.map(({ description, values }) => ({ type: 'EXCLUDE', options: values, description }));
  const includeValuesValidator = form.isDropdownField ? [{ options: form.dropdownFieldOptions, type: 'INCLUDE' }] : [];
  const validators = [
    ...regexValidators,
    ...includeValuesValidator,
    ...excludedValuesValidators,
  ];
  return {
    fin: form.fin,
    name: form.name,
    description: form.description,
    validators,
    associatedEntityType: form.associationType === 'ENTITY' ? form.associatedET?.fin : null,
    associatedEntityRelationshipTypes: form.associationType === 'ENTITY_RELATIONSHIP' ? {
      parent: form.associatedRET[0]?.fin as string,
      child: form.associatedRET[1]?.fin as string,
    } : null,
    isStatic: form.isStatic,
    type: form.dataType,
  };
}
