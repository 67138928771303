import dayjs from 'dayjs';
import { z } from 'zod';

const metricFormValidationSchema = z.object({
  fin: z.string({
    required_error: 'Required',
  }).min(1, 'Required'),
  name: z.string({
    required_error: 'Required',
  }).min(1, 'Required'),
  description: z.string(),
  regexRules: z.array(z.object({
    regex: z.string().min(1, 'Required'),
    description: z.string().min(1, 'Required'),
    type: z.string().min(1, 'Required'),
  })),
  excludeValueLists: z.array(z.object({
    id: z.string(),
    description: z.string(),
    values: z.array(z.any()),
  }).refine((data) => !data.values.some((v) => !v.length), { message: 'Values can not be empty' })
    .refine((data) => data.description.length, { message: 'Description Required' })),
  associationType: z.string(),
  associatedET: z.any(),
  associatedRET: z.any(),
  isStatic: z.boolean(),
  dataType: z.string(),
  isDropdownField: z.boolean(),
  dropdownFieldOptions: z.array(z.string()),
}).refine((data) => {
  // Validate that associatedET is not null when associationType is 'ENTITY'
  if (data.associationType === 'ENTITY') {
    return data.associatedET !== null;
  }
  return true;
}, {
  message: 'Required',
  path: ['associatedET'],
}).refine((data) => {
  if (data.associationType === 'ENTITY_RELATIONSHIP') {
    return Array.isArray(data.associatedRET)
      && data.associatedRET.length === 2
      && data.associatedRET.every((item) => item !== null);
  }
  return true;
}, {
  message: 'Required',
  path: ['associatedRET'],
}).refine((data) => {
  if (!data.isDropdownField) {
    return true;
  }

  if (data.dropdownFieldOptions.filter((o) => o === '' || !o).length === 0) {
    return true;
  }
  return false;
}, {
  message: 'Dropdown options can not be empty',
  path: ['dropdownOptions'],
})
  .refine((data) => {
    if (!data.isDropdownField) {
      return true;
    }

    if (data.dropdownFieldOptions.length > 0) {
      return true;
    }
    return false;
  }, {
    message: 'At least one value required',
    path: ['dropdownOptions'],
  })
  .refine((data) => { // All Dropdown options for numeric data types must be valid numbers
    const numericTypes = ['NUMERIC', 'PERCENTAGE', 'CURRENCY'];
    if (numericTypes.includes(data.dataType)) {
      const isInvalidDropdownOptions = data.dropdownFieldOptions.some(
        (option) => Number.isNaN(Number(option)),
      );
      return !isInvalidDropdownOptions;
    }
    return true;
  }, {
    message: 'Dropdown options must be valid numbers only',
    path: ['dropdownOptions'],
  })
  .refine((data) => { // All Dropdown options for date data type must be valid dates
    if (data.dataType === 'DATE') {
      const isInvalidDropdownOptions = data.dropdownFieldOptions.some(
        (option) => !dayjs(option, 'DD/MM/YYYY', true).isValid(),
      );
      return !isInvalidDropdownOptions;
    }
    return true;
  }, {
    message: 'Dropdown options must be valid dates only (DD/MM/YYYY)',
    path: ['dropdownOptions'],
  });

export default metricFormValidationSchema;
